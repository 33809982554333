<template>
    <section>
        <b-row>
            <b-col cols="12">
                <b-card>
                    <b-alert
                        v-height-fade.appear
                        :show="validationErrors.length > 0"
                        variant="danger"
                        dismissible
                    >
                        <div
                            class="alert-body"
                            v-for="(validationError, index) in validationErrors"
                            :key="index"
                        >
                            <small>
                                {{ validationError }}
                            </small>
                        </div>
                    </b-alert>
                    <h3 class="">Create New User</h3>
                    <validation-observer ref="newUserForm">
                        <b-form
                            class="auth-login-form mt-2"
                            @submit.prevent="newUserHandler"
                        >
                            <div class="row">
                                <div class="col-md-6">
                                    <b-form-group
                                        label="First Name"
                                        label-for="first-name"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="first_name"
                                            vid="first_name"
                                            rules="required"
                                        >
                                            <b-form-input
                                                id="first-name"
                                                v-model="firstNameValue"
                                                :state="
                                                    errors.length > 0
                                                        ? false
                                                        : null
                                                "
                                                name="first_name"
                                                placeholder="First Name"
                                            />
                                            <small class="text-danger">{{
                                                errors[0]
                                            }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                                <div class="col-md-6">
                                    <b-form-group
                                        label="Last Name"
                                        label-for="last-name"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="last_name"
                                            vid="last_name"
                                            rules="required"
                                        >
                                            <b-form-input
                                                id="last-name"
                                                v-model="lastNameValue"
                                                :state="
                                                    errors.length > 0
                                                        ? false
                                                        : null
                                                "
                                                name="last_name"
                                                placeholder="Last Name"
                                            />
                                            <small class="text-danger">{{
                                                errors[0]
                                            }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                                <div class="col-md-6">
                                    <b-form-group
                                        label="Username"
                                        label-for="username"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="username"
                                            vid="username"
                                            rules="required"
                                        >
                                            <b-form-input
                                                id="username"
                                                v-model="usernameValue"
                                                :state="
                                                    errors.length > 0
                                                        ? false
                                                        : null
                                                "
                                                name="username"
                                                placeholder="Username"
                                            />
                                            <small class="text-danger">{{
                                                errors[0]
                                            }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                                <div class="col-md-6">
                                    <b-form-group
                                        label="Email"
                                        label-for="email"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="email"
                                            vid="email"
                                            rules="required|email"
                                        >
                                            <b-form-input
                                                id="email"
                                                v-model="emailValue"
                                                :state="
                                                    errors.length > 0
                                                        ? false
                                                        : null
                                                "
                                                name="email"
                                                placeholder="email"
                                            />
                                            <small class="text-danger">{{
                                                errors[0]
                                            }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                                <div class="col-md-6">
                                    <b-form-group
                                        label="Password"
                                        label-for="password"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="password"
                                            vid="password"
                                            rules="required|password"
                                        >
                                            <b-form-input
                                                id="password"
                                                v-model="passwordValue"
                                                :state="
                                                    errors.length > 0
                                                        ? false
                                                        : null
                                                "
                                                name="password"
                                                placeholder="password"
                                            />
                                            <small class="text-danger">{{
                                                errors[0]
                                            }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                                <div class="col-md-6">
                                    <b-form-group
                                        label="Birth Date"
                                        label-for="birth-date"
                                    >
                                        <b-form-datepicker
                                            :date-format-options="{
                                                year: 'numeric',
                                                month: 'numeric',
                                                day: 'numeric',
                                            }"
                                            id="birth-date"
                                            v-model="birthDateValue"
                                        />
                                    </b-form-group>
                                </div>
                                <div class="col-md-6">
                                    <b-form-group
                                        label="Linkedin"
                                        label-for="linkedin"
                                    >
                                        <b-form-input
                                            id="linkedin"
                                            v-model="linkedinValue"
                                            name="linkedin"
                                            placeholder="Linkedin"
                                        />
                                    </b-form-group>
                                </div>
                                <div class="col-md-6">
                                    <b-row>
                                        <div class="col-md-4">
                                            <b-form-group
                                                label="Country"
                                                label-for="register-phone"
                                            >
                                                <v-select
                                                    v-model="country"
                                                    dir="ltr"
                                                    label="code"
                                                    :options="countryOptions"
                                                >
                                                    <template #no-options>
                                                        Country Not Found.
                                                    </template>
                                                </v-select>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-8">
                                            <b-form-group
                                                label="Phone Number"
                                                label-for="phone_number"
                                            >
                                                <b-form-input
                                                    id="phone_number"
                                                    v-model="phoneNumberValue"
                                                    name="phone_number"
                                                    placeholder="Phone Number"
                                                />
                                            </b-form-group>
                                        </div>
                                    </b-row>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <b-form-group label="Role" label-for="role">
                                        <validation-provider
                                            #default="{ errors }"
                                            name="role"
                                            vid="role"
                                            rules="required"
                                        >
                                            <v-select
                                                v-model="selectedRole"
                                                dir="ltr"
                                                label="name"
                                                :options="rolesOptions"
                                            >
                                                <template #no-options>
                                                    Roles Not Found.
                                                </template>
                                            </v-select>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                                <div class="col-md-4 align-self-center">
                                    <b-form-group
                                        label="Active"
                                        label-for="active"
                                    >
                                        <div class="demo-inline-spacing">
                                            <b-form-radio
                                                v-model="userActiveValue"
                                                name="user_active"
                                                value="active"
                                                class="
                                                    custom-control-primary
                                                    mt-0
                                                "
                                            >
                                                Active
                                            </b-form-radio>
                                            <b-form-radio
                                                v-model="userActiveValue"
                                                name="user_active"
                                                value="deactive"
                                                class="
                                                    custom-control-primary
                                                    mt-0
                                                "
                                            >
                                                Deactive
                                            </b-form-radio>
                                        </div>
                                    </b-form-group>
                                </div>
                                <div class="col-md-4 align-self-center">
                                    <b-form-group
                                        label="Verify"
                                        label-for="verify"
                                    >
                                        <div class="demo-inline-spacing">
                                            <b-form-radio
                                                v-model="userVerifiedValue"
                                                name="user_verify"
                                                value="verify"
                                                class="
                                                    custom-control-primary
                                                    mt-0
                                                "
                                            >
                                                Verify
                                            </b-form-radio>
                                            <b-form-radio
                                                v-model="userVerifiedValue"
                                                name="user_verify"
                                                value="unverify"
                                                class="
                                                    custom-control-primary
                                                    mt-0
                                                "
                                            >
                                                Unverify
                                            </b-form-radio>
                                        </div>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    <b-button type="submit" variant="primary">
                                        Add User
                                    </b-button>
                                </div>
                                <div class="offset-md-7"></div>
                            </div>
                        </b-form>
                    </validation-observer>
                </b-card>
            </b-col>
        </b-row>
    </section>
</template>
<script>
import _ from "lodash";
import { required, email, password } from "@validations";
import { heightFade } from "@core/directives/animations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BAlert,
    BFormDatepicker,
    BFormRadio,
} from "bootstrap-vue";
import axiosIns from "@/libs/axios";
import vSelect from "vue-select";
export default {
    directives: {
        "height-fade": heightFade,
    },
    components: {
        BRow,
        BCol,
        BCard,
        BFormGroup,
        BFormInput,
        vSelect,
        BForm,
        BButton,
        BAlert,
        BFormDatepicker,
        BFormRadio,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            // user parameters
            firstNameValue: undefined,
            lastNameValue: undefined,
            usernameValue: undefined,
            emailValue: undefined,
            passwordValue: undefined,
            birthDateValue: undefined,
            userActiveValue: "deactive",
            userVerifiedValue: "unverify",
            country: undefined,
            countryOptions: [],
            linkedinValue: undefined,
            phoneNumberValue: undefined,
            selectedRole: undefined,
            rolesOptions: [],
            // validation rules
            required,
            password,
            email,
            // error handling
            validationErrors: [],
        };
    },
    created() {
        this.getCountries();
        this.getRoles();
    },
    methods: {
        getRoles() {
            axiosIns
                .get("https://api.soclib.net/api/roles")
                .then(({ data }) => {
                    this.rolesOptions = data;
                });
        },
        getCountries() {
            axiosIns
                .get("https://api.soclib.net/api/countries")
                .then(({ data }) => {
                    this.countryOptions = data;
                });
        },
        // add new user
        newUserHandler() {
            // validate newUserForm form
            this.$refs.newUserForm.validate().then((success) => {
                if (success) {
                    const params = {
                        first_name: this.firstNameValue,
                        last_name: this.lastNameValue,
                        username: this.usernameValue,
                        email: this.emailValue,
                        linkedin: this.linkedinValue,
                        password: this.passwordValue,
                        birth_date: this.birthDateValue,
                        user_active: this.userActiveValue,
                        user_verify: this.userVerifiedValue,
                        phone_number: this.phoneNumberValue,
                        country: this.country ? this.country.code : undefined,
                        role: this.selectedRole.name
                    };

                    axiosIns
                        .post("https://api.soclib.net/api/users", params, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem(
                                    "accessToken"
                                )}`,
                            },
                        })
                        .then((response) => {
                            // set alert parameters
                            this.$store.commit("posts/setAlert", {
                                text: "New user created successfully",
                                type: "success",
                                visible: true,
                                count: 10,
                            });
                            // navigate to users list page
                            return this.$router.push("/dashboard/users");
                        })
                        .catch((error) => {
                            this.validationErrors = _.flatten(
                                Object.values(error.response.data)
                            );
                        });
                }
            });
        },
    },
};
</script>
<style scoped></style>
